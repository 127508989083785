import React from 'react';
import { Helmet } from 'react-helmet';
import { Grid } from '@devstart/react-bootstrap';
import { useTranslation } from 'react-i18next';

import { Spacer } from '../components/helpers';
import Button from '../components/ui/Button';
import { ButtonTypes } from '../components/ui/Button/button-types';
import { apiLocation } from '../../../config/env.json';

import './sent-email-to-recovery.css';
function SentEmailToRecovery(): JSX.Element {
  const { t } = useTranslation();

  return (
    <>
      <Helmet title={`${t('sent-email-to-recovery.title')} | DEVstart`} />
      <Grid>
        <Spacer size='exLarge' />
        <div className='sent-email-container'>
          <div className='info-sent-email-container'>
            <p id='sent-email-title'>{t('sent-email-to-recovery.title')}</p>
            <Spacer size='small' />
            <p id='sent-email-subtitle'>
              {t('sent-email-to-recovery.subtitle')}
            </p>
            <Spacer size='medium' />
            <Button
              href={`${apiLocation}/signout`}
              target='_self'
              buttonType={ButtonTypes.Light}
              style={{ height: '48px', width: '100%' }}
            >
              {t('sent-email-to-recovery.buttons.go-to-login')}
            </Button>
          </div>
        </div>
      </Grid>
    </>
  );
}

SentEmailToRecovery.displayName = 'SentEmailToRecoveryPage';

export default SentEmailToRecovery;
